<template>
	<PageLayout :title="$t('deposit.title')">
		<div v-if="!loading" class="cashier-box cashier-box--deposit">
			<payment-logo :custom-logo="'m-pesa-logo.svg'" />

			<div class="small-width overflow-visible">
				<payment-wallet />
			</div>

			<div class="line-separator-wrapper">
				<div class="line-separator" />
			</div>

			<div class="small-width">
				<MaterialPhoneInput :mobilenumber="form.phone.mobilenumber" @set-mobile-number="setMobileNumber" />
				<div v-if="!!formErrors['mobilenumber']" class="error">
					{{ !!formErrors ? formErrors['mobilenumber'] : '' }}
				</div>
			</div>

			<amount-buttons
				:buttons-value="currentMethod.predefinedDepositAmounts"
				:form-amount-value="form.amount || currentMethod.predefinedDepositAmounts[0]"
				:currency="currency"
				:form-errors="formErrors['amount']"
				@set-button-value="setButtonValue"
			/>

			<div class="small-width">
				<promocode
					:form-promo-value="form.promocode"
					:form-error="formErrors['promocode']"
					@set-promo-value="setPromoValue"
				/>
			</div>

			<button type="button" class="button primary-button deposit center" @click="submit()">
				{{ $t('deposit.top-up') }}
			</button>
		</div>

		<div v-if="!loading" class="method-description-cashier">
			{{ $t(`deposit.method-descriptions.mpesa`) }}
		</div>

		<div v-if="loading" class="loader-wrapper">
			<loader />
		</div>
	</PageLayout>
</template>
<script>
import { mapGetters } from 'vuex';
import PageLayout from '@/components/page-layout/PageLayout';
import apiClient from '../../../../api';
import Analytics from '@/utils/analytics';

const loader = () => import('../../../../shared/loader/Loader.vue');
const materialPhoneInput = () => import('../../../../shared/material-phone-input/MaterialPhoneInput');
const amountButtons = () => import('../../../../shared/amount-buttons/AmountButtons');
const promocode = () => import('../../../../shared/promocode/Promocode');
const paymentLogo = () => import('../../../../shared/payment-logo/PaymentLogo');
const paymentWallet = () => import('../../../../shared/payment-wallet/PaymentWallet');

export default {
	name: 'DepositMpesa',
	components: {
		PageLayout,
		loader,
		materialPhoneInput,
		amountButtons,
		promocode,
		paymentLogo,
		paymentWallet,
	},
	data() {
		return {
			currency: null,
			form: {
				phone: {
					mobilenumber: '',
					isValid: false,
				},
				amount: null,
				promocode: null,
			},
			formErrors: {
				mobilenumber: null,
				amount: null,
				promocode: null,
			},
			loading: false,
		};
	},
	computed: {
		...mapGetters(['getUser']),
		currentMethod() {
			return this.$store.getters.getCurrentMethod('mpesa');
		},
	},
	watch: {
		currency(newVal) {
			this.setAmountsForCurrency(newVal);
		},
	},
	async mounted() {
		this.form.phone.mobilenumber = this.getUser.mobilenumber;
		this.currency = this.getUserCurrency();
	},
	methods: {
		getUserCurrency() {
			if (this.getUser.localCurrency === 'TZS') {
				return 'TZS';
			}

			return 'KES';
		},
		setAmountsForCurrency(currency) {
			switch (currency) {
				case 'TZS':
					this.form.amount = 15000;
					this.amounts = [15000, 30000, 50000, 100000];
					break;
				case 'KES':
					this.form.amount = 1000;
					this.amounts = [600, 1000, 1500, 5000];
					break;
				default:
					this.form.amount = null;
					this.amounts = [];
			}
		},
		setButtonValue(event) {
			this.form.amount = event;
		},
		setPromoValue(event) {
			this.form.promocode = event;
		},
		async submit() {
			const { mobilenumber } = this.form.phone;
			const phoneValid = this.form.phone.isValid;
			const { amount } = this.form;
			const { currency } = this;
			const { promocode } = this.form;

			this.formErrors = {
				mobilenumber: !this.form.phone.mobilenumber
					? this.$t('errors.required')
					: !this.form.phone.isValid
					? this.$t('errors.invalid-number')
					: null,
				amount: !this.form.amount ? this.$t('errors.required') : null,
			};

			if (phoneValid && amount) {
				this.loading = true;
				try {
					const response = await apiClient.depositMpesa(this.walletId, mobilenumber, amount, currency, promocode);
					if (response.status === 201) {
						this.loading = false;

						Analytics.trackEvent({
							event: 'Deposit',
							value: this.form.amount,
							label: 'Mpesa',
						});

						this.$router.push({
							path: '/payments/deposit/confirmation',
							query: {
								status: 'success',
								method: 'mpesa',
							},
						});
					}
				} catch (error) {
					this.loading = false;
					const walletId = this.$route.params.walletId;
					this.$router.push({
						path: `/payments/deposit/${walletId}/confirmation`,
						query: { status: 'error', method: 'mpesa' },
					});
				}
			}
		},
		setMobileNumber(event) {
			this.form.phone.mobilenumber = event.number.e164;
			this.form.phone.isValid = event.isValid;
		},
	},
};
</script>
<style scoped>
button.btn-submit {
	margin-top: 35px;
}

.cashier-box {
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
}
</style>
